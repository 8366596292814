import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import API from '../../api/api';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async ({ page, limit, category = '', apiInstance = API }, thunkAPI) => {
  try {
    // Build params including page requested and how many items/page desired
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    // Check if a category is provided and build the query string accordingly
    if (category) {
      params.append('category', category);
    }

    const response = await apiInstance.get(`/api/products?${params.toString()}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// Thunk to fetch products based on search
export const searchProducts = createAsyncThunk('products/searchProducts', async ({ page, limit, searchTerm = '', apiInstance = API}, thunkAPI) => {
  try {
    // Build params including page requested and how many items/page desired
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    params.append('searchQuery', searchTerm);

    //const response = await API.get(`/api/products/search?searchQuery=${searchTerm}`);
    const response = await apiInstance.get(`/api/products/search?${params.toString()}`);
    console.log('response: ', response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle no products found
      return thunkAPI.rejectWithValue('No products found.');
    }
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const fetchProductById = createAsyncThunk('products/fetchProductById', async (id) => {
  const response = await API.get(`/api/products/${id}`);
  console.log(response);
  return response.data;
});

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    currentPage: 1,
    totalPages: 1,
    product: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        //console.log('products post search:', state.products);
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
        //console.log(`state.product:`, JSON.stringify(state.product, null, 2));
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = false;
        console.log('action.error: ', action.error);
        state.error = action.error;
        console.log('state.error: ', state.error);
      });
  },
});

export default productsSlice.reducer;
