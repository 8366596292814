import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);


  useEffect(() => {
    if (isClient) {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page 
    }
  }, [pathname]); // Fires when the pathname changes

  return null;
};

export default ScrollToTop;