// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import productsReducer from './products/productsSlice';
import categoriesReducer from './categories/categoriesSlice';
import cartReducer from './currentCart/currentCart';
import profileReducer from './profile/profileSlice';
import ordersReducer from './orders/ordersSlice';

export const configureAppStore = (preloadedState) => {
  return configureStore({
    reducer: {
      auth: authReducer, 
      products: productsReducer,
      categories: categoriesReducer,
      cart: cartReducer,
      profile: profileReducer,
      orders: ordersReducer,
    },
    preloadedState,
  });
};

// Export the store instance
//const store = configureAppStore(window.__PRELOADED_STATE__ || {});

export default configureAppStore;