// src/redux/profileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import API from '../../api/api';
//axios.defaults.withCredentials = true;

// Thunk to fetch user profile
export const fetchUserProfile = createAsyncThunk('profile/fetchUserProfile', async (apiInstance = API, thunkAPI) => {
  try {
    const response = await apiInstance.get('/api/users/profile');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// Thunk to update user profile
export const updateUserProfile = createAsyncThunk('profile/updateUserProfile', async (userData) => {
  const { userId, ...rest } = userData;
  const response = await API.put(`/api/users/${userId}`, rest);
  return response.data;
});

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetch profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        console.log(state.user);
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Handle update profile
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        console.log('user: ', state.user);
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default profileSlice.reducer;