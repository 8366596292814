// App.js
import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkSession } from '../redux/auth/authSlice';
import { fetchCart } from '../redux/currentCart/currentCart';
import { logoutFromServer } from '../api/api';
import { logout } from '../redux/auth/authSlice';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
//import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer/Footer';
import Loading from '../components/Loading/Loading';
import { AnimatePresence } from 'framer-motion';
import styles from './App.module.css';

const NavBar = loadable(() => import('../components/Navbar/NavBar'), {
  fallback: <Loading />,
});
const HomePage = loadable(() => import('../pages/HomePage/HomePage'), {
  fallback: <Loading />,
});
const RegisterPage = loadable(() => import('../pages/RegisterPage/RegisterPage'), {
  fallback: <Loading />,
});
const Login = loadable(() => import('../pages/LoginPage/LoginPage'), {
  fallback: <Loading />,
});
const Profile = loadable(() => import('../pages/ProfilePage/ProfilePage'), {
  fallback: <Loading />,
});
const AdminDashboard = loadable(() => import('../admin/AdminDashboard/AdminDashboard'), {
  fallback: <Loading />,
});
const ProductsPage = loadable(() => import('../pages/ProductsPage/ProductsPage'), {
  fallback: <Loading />,
});
const ProductDetails = loadable(() => import('../pages/ProductDetails/ProductDetails'), {
  fallback: <Loading />,
});
const CategoryPage = loadable(() => import('../pages/CategoryPage/CategoryPage'), {
  fallback: <Loading />,
});
const SearchResults = loadable(() => import('../pages/SearchResults/SearchResults'), {
  fallback: <Loading />,
});
const Cart = loadable(() => import('../pages/CartPage/CartPage'), {
  fallback: <Loading />,
});
const CheckoutPage = loadable(() => import('../pages/CheckoutPage/CheckoutPage'), {
  fallback: <Loading />,
});
const OrderConfirmation = loadable(() => import('../pages/OrderConfirmation/OrderConfirmation'), {
  fallback: <Loading />,
});
const OrdersPage = loadable(() => import('../pages/OrdersPage/OrdersPage'), {
  fallback: <Loading />,
});
const OrderDetails = loadable(() => import('../pages/OrderDetails/OrderDetails'), {
  fallback: <Loading />,
});
const AdminRoute = loadable(() => import('../components/AdminRoute/AdminRoute'), {
  fallback: <Loading />,
});
const NotAuthorized = loadable(() => import('../pages/NotAuthorized/NotAuthorized'), {
  fallback: <Loading />,
});
//const PrivateRoute = lazy(() => import('../components/PrivateRoute/PrivateRoute'));



//const HomePage = lazy(() => import('../pages/HomePage/HomePage'));
//const AboutPage = lazy(() => import('./pages/AboutPage/AboutPage'));

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {loading, isLoggedIn} = useSelector((state) => state.auth);
  const cartItemCount = useSelector((state) => state.cart.totalQuantity);
  const navigate = useNavigate(); // Use navigate inside the Router

  const [isClient, setIsClient] = useState(false);
  
  useEffect(() => {
    setIsClient(true);
    console.log("session check App.js: ", isLoggedIn);
  }, []);

  useEffect(() => {
    if (isLoggedIn === null) {
      dispatch(checkSession());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    dispatch(fetchCart());
  }, []);

  const handleLogout = async () => {
    try {
      await logoutFromServer(); // Call the server to log out
      dispatch(logout());       // Update Redux state to log out
      navigate('/login');       // Redirect to login after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) return <Loading />;
  return (
    <>
      <ScrollToTop />
      {location.pathname !== '/admin-dashboard' && <NavBar 
        isLoggedIn={isLoggedIn}
        onLogout={handleLogout}
        cartItemCount={cartItemCount}
      />}
      <AnimatePresence mode='wait'>
      <Routes>
        <Route path ="/" element={<HomePage/>} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin-dashboard" element={
          <AdminRoute>
            <AdminDashboard />
          </AdminRoute>
        } />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        <Route path="/category/:categoryId" element={<CategoryPage />} />
        <Route path="/search/:searchQuery" element={<SearchResults />} />
        <Route path="/cart" element={<Cart isLoggedIn={isLoggedIn} />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/order-confirmation" element={<OrderConfirmation />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/orders/:id" element={<OrderDetails />} />
        {isClient && <Route path="*" element={<Navigate to="/" />} />}
      </Routes>
      </AnimatePresence>
      {location.pathname !== '/admin-dashboard' && <Footer />}
    </>
  );
};

export default AppRoutes;
