// src/redux/categoriesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import API from '../../api/api';

// Thunk to fetch categories
export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (apiInstance = API, thunkAPI) => {
  try {
    const response = await apiInstance.get('/api/categories');
    return response.data;
  } catch(error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const fetchFeaturedCategories = createAsyncThunk('categores/fetchFeaturedCategories', async (apiInstance = API, thunkAPI) => {
  try {
    const response = await apiInstance.get('/api/categories/featured');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    featuredCategories: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFeaturedCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeaturedCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.featuredCategories = action.payload;
      })
      .addCase(fetchFeaturedCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default categoriesSlice.reducer;