import React from 'react';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'; // Import icons
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; 

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={styles.container}>
      <div className={styles.column}>
        <h6 className={styles.title}>Quick Links</h6>
        <nav className={styles.nav}>
          <a href="/about" className={styles.link}>About</a>
          <a href="/contact" className={styles.link}>Contact</a>
          {/* Add more quick links here */}
        </nav>
      </div>
      <div className={styles.column}>
        <h6 className={styles.title}>Company</h6>
        <nav className={styles.nav}>
          <a href="/terms" className={styles.link}>Terms of Use</a>
          <a href="/privacy" className={styles.link}>Privacy Policy</a>
          {/* Add more company links here */}
        </nav>
      </div>
      <div className={styles.column}>
        <h6 className={styles.title}>Stay Connected</h6>
        <nav className={`${styles.nav} ${styles.socials}`}>
          <a href="https://www.facebook.com/KMI-International-Corp-463339271120886/" className={styles.link}>
            <FontAwesomeIcon icon={faFacebook} className={`${styles.icon} ${styles.facebook}`} />
            
          </a>
          <a href="https://www.instagram.com/kmi_international_corp/?hl=en" className={styles.link}>
            <FontAwesomeIcon icon={faInstagram} className={`${styles.icon} ${styles.instagram}`} />
        
          </a>
          <a href="https://twitter.com/CorpKmi" className={styles.link}>
            <FontAwesomeIcon icon={faTwitter} className={`${styles.icon} ${styles.twitter}`} />
         
          </a>
          <a href="mailto:sales@floralkmi.com" className={styles.link}>
            <FontAwesomeIcon icon={faEnvelope} className={`${styles.icon} ${styles.email}`} />
           
          </a>
        </nav>
      </div>
      
      </div>
      <div className={styles.copyright}>
        <p>KMI International Corp. © 2024</p>
      </div>
    </footer>
  );
};

export default Footer;