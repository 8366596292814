// src/components/Loading.js
import React from 'react';
import styles from './Loading.module.css';

function Loading() {
  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.spinner}></div>
      <h2>Loading, please wait...</h2>
    </div>
  );
}

export default Loading;